<template>
  <v-app>
    <v-app-bar style="z-index: 998;" max-height="70px"  clipped-left color="rgba(231, 243, 254, 0.3)" dark
               v-if="alias != '/login'">
      <div class="d-flex align-center"></div>
      <!-- <v-toolbar-title>Životopis nastavnika FPMOZ-a</v-toolbar-title> -->
      <v-btn light="" class="hidden-md-and-up" @click.stop="drawer = !drawer" icon>
        <v-icon color="#084776">fas fa-bars</v-icon>
      </v-btn>
      <v-spacer class="hidden-md-and-up"></v-spacer>
      <a href="https://sum.ba" target="_blank" class="mt-3 mb-1">
        <img height="50" :src="require('@/assets/img/sum-blue.svg')">
      </a>
      <!-- <v-btn class="ml-4 hidden-sm-and-down" outlined color="#084776" target="_blank" href="/dokumenti/upute_nastavnici.pdf"><v-icon x-small left>far fa-file-alt</v-icon>Upute</v-btn> -->
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <v-btn class="ml-4 hidden-sm-and-down" outlined color="#084776" v-if="loggedIn" :to="'/' + id">
        <v-icon small left>mdi-account-outline</v-icon>
        Profil
      </v-btn>
      <v-btn class="ml-4 hidden-sm-and-down" outlined color="#084776" to="/">
        <v-icon small left>mdi-home-outline</v-icon>
        Naslovnica
      </v-btn>
      <v-btn class="ml-4 hidden-sm-and-down" outlined color="#084776" to="/info">
        <v-icon small left>mdi-information-outline</v-icon>
        Info
      </v-btn>
      <v-btn class="ml-4 hidden-sm-and-down" outlined color="#084776" v-if="!loggedIn" @click="login()">
        <v-icon small left>mdi-login-variant</v-icon>
        Prijava
      </v-btn>
      <v-btn class="ml-4 hidden-sm-and-down" outlined color="#084776" v-if="loggedIn" @click="logout()">
        <v-icon x-small left>mdi-logout</v-icon>
        Odjava
      </v-btn>

    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary app v-if="alias != '/login'" style="z-index: 999"
                         class="hidden-md-and-up">
      <v-row justify="center">
        <v-col cols="12" class="text-center">
            <img height="70" width="130" :src="require('@/assets/img/sum-blue.svg')" />

        </v-col>
      </v-row>
      <p class="text-center ma-2">
        <b>Sveučilište u Mostaru</b>
      </p>

      <v-divider></v-divider>

      <v-list dense>
        <router-link v-if="loggedIn" :to="'/' + id" class="link">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Profil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <div v-for="item in items" :key="item.title">
          <router-link :to="item.link" class="link">
            <v-list-item link>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>
        <a v-if="!loggedIn" @click="login()" class="link">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-login-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Prijava</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </a>
        <a v-if="loggedIn" class="link" @click="logout()">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Odjava</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </a>
        <!-- <a href="/dokumenti/upute_nastavnici.pdf" target="_blank" class="link">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>far fa-file-alt</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Upute</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </a> -->
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <!-- <v-fade-transition  mode="out-in"> -->
      <router-view :key="$route.fullPath"></router-view>
      <!-- </v-fade-transition> -->
    </v-main>

    <v-footer clipped-left dark color="rgb(117, 127, 149)" height="50" style="z-index:: 999">
      <v-layout
          row
          color="#084776"
          class="pt-1 pb-1 white--text text-center"
          align-center
          justify-center
      >
        <p class="footer-title" style="font-size: 15px; margin-top: auto; margin-bottom:auto;">
          Copyright ©
          <strong>
            <a class="footer-link" href="https://sumit.sum.ba/" target="_blank">SUMIT</a>
          </strong>
          {{ new Date().getFullYear() }}
        </p>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
// import HomePage from './components/HomePage';
import axios from "axios";
import {request_url_hrm} from "./variables.js";

export default {
  name: "App",

  created() {
    if (this.$route.query.action === 'user')
      this.login()
  },

  mounted() {
    this.alias = this.$route.path;

    if (localStorage.getItem("token") == null) {

      this.loggedIn = false;
      this.id = null
      // console.log(localStorage.getItem("token"))
    } else if (localStorage.getItem("token") == "-1") {
      this.loggedIn = false;
      this.id = null
      // console.log(localStorage.getItem("token"))
    } else {
      // console.log(this.loggedIn)
      this.loggedIn = true;
      this.id = localStorage.getItem("aai_kor_ime");
    }
  },

  methods: {
    logout() {
      localStorage.setItem("token", "-1");
      localStorage.setItem("aai_kor_ime", null);
      localStorage.setItem("aai_kor_ime", null);
      this.$router.push(this.$route.path)
      let host = `${window.location.protocol}//${location.host}`;
      window.location.href = `https://api.hrm.sum.ba/sso/logout?redirect_to=${host}`;
    },

    login() {
      this.$router.replace({query: Object.assign({...this.$route.query}, {action: 'user'})}, () => {
      });
      // console.log(123456)
      axios
          .get(request_url_hrm + "sso/user")
          .then(response => {
            console.log(response.data)
            localStorage.setItem("token", 1);
            localStorage.setItem("osobni_id", response.data.uposlenik_id)
            localStorage.setItem("aai_kor_ime", response.data.uid)
            if (localStorage.getItem("token") == null) {

              this.loggedIn = false;
              this.id = null
              // console.log(localStorage.getItem("token"))
            } else if (localStorage.getItem("token") == "-1") {
              this.loggedIn = false;
              this.id = null
              // console.log(localStorage.getItem("token"))
            } else {
              // console.log(this.loggedIn)
              this.loggedIn = true;
              this.id = localStorage.getItem("aai_kor_ime");
            }
          })
          .catch((err) => {
            console.log(err)
            localStorage.setItem("token", "-1");
            localStorage.setItem("aai_kor_ime", null);
            localStorage.setItem("aai_kor_ime", null);
          })
    },
  },

  computed: {
    routeKey() {
      const keyBuilder = this.$route.matched[0].meta.keyBuilder;
      return keyBuilder ? keyBuilder(this.$route) : this.$route.path;
    }
  },
  watch: {
    alias: function () {
      this.alias = this.$route.path;
    }
  },

  data: () => ({
    id: null,
    token: "-1",
    loggedIn: false,
    alias: "",
    drawer: false,
    items: [
      {title: "Naslovnica", link: "/", icon: "mdi-home-outline"},
      {title: "Info", link: "/info", icon: "mdi-information-outline"}
      // { title: "Prijava", link: "/login", icon: "fas fa-sign-in-alt" }
    ]
  })
};
</script>
