<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="10" offset-sm="1">
        <p class="display-1 hidden-xs-only mb-0" style="color: #084776">Informacije o podacima unutar CV aplikacije</p>
        <p class="title hidden-sm-and-up mb-0" style="color: #084776">Informacije o podacima unutar CV aplikacije</p>
      </v-col>
      <v-col cols="12" sm="10" offset-sm="1">
        <div>Unutar CV aplikacije nalaze se svi podaci o nastavnom i nenastavnom osoblju Sveučilišta u Mostaru.
          Pretraživanje je moguće preko globalne pretrage, unosom imena i prezimena osobe.
          Kada uđemo u profil osobe vidimo neke osobne podatke koji se povlače iz aplikacija za menadžment ljudskih potencijala (HRM),
          zatim podaci o radovima osobe povlače se iz Registra Sveučilišnih radova i podatke o projektima koji se povlače iz Registra Sveučilišnih projekata.</div>
      </v-col>
      <v-col cols="12" sm="8" offset-sm="2" >
        <v-img :src="require('@/assets/img/cv_app_grafika_new.png')"></v-img>
      </v-col>
      <v-col cols="12" sm="10" offset-sm="1">
        <div class="text-h6" style="color: #084776">Opis svakog pojedinog podatka i kako ga promijeniti</div>
        <div class="mt-2">
          Podaci koji se nalaze i mjenjaju u HRM-u:
          <ul>
            <li>Ime i prezime</li>
            <li>Titula</li>
            <li>Akademsko zvanje</li>
            <li>Datum izbora u zvanje</li>
            <li>Matična ustanova</li>
            <li>Dodatno raspoređen</li>
            <li>Matični studij</li>
            <li>Službeni email</li>
          </ul>
          <p>Za izmjenu ovih podatak potrebno se obratiti odgovornoj osobi sa vaše ustrojbene jedinice.
            Popis ISS administratora po ustrojbenim jedinicama nalazi se na sljedećoj <a href="https://eduid.sum.ba/koordinatori#koordinatori" target="_blank">poveznici</a>.</p>
        </div>
        <div class="mt-2">
          Podaci koji se nalaze i mjenjaju unutar CV aplikacije:
          <ul>
            <li>Privatni eEmail</li>
            <li>Broj telefona</li>
            <li>Broj ureda</li>
            <li>Lokacija ureda</li>
            <li>ORCID broj</li>
            <li>Poveznica na Google Scholar</li>
            <li>Poveznica na Research Gate</li>
            <li>Kratki životopis</li>
          </ul>
          <p>Ove podatke svaka osoba može samostalno promjeniti unutar CV aplikacije prijavom sa svojim eduID korisničkim podacima.</p>
        </div>
        <div class="mt-2">Profilnu sliku korisnik može unijeti i promijeniti na <a href="https://eduid.sum.ba/korisnik" target="_blank">eduID profilu</a> prijavom sa svojim eduID korisničkim podacima.</div>
        <div class="mt-3">
          Objavljeni radovi se nalaze i mjenjaju unutar Registra Sveučilišnih Radova. Svaka osoba može samostalno unijeti rad unutar aplikacije Registar radova
          <a href="https://pub.sum.ba" target="_blank">https://pub.sum.ba</a> prijavom sa svojim eduID korisničkim podacima. Nakon unosa rada potrebno je da
          odgovorna osoba sa ustrojbene jedinice verificira rad kako bi se rad pojavio na profilu osobe unutar CV aplikacije.
        </div>
        <div class="mt-3">
          Projekti se nalaze i mjenjaju unutar Registra Sveučilišnih Projekata. Svaka osoba može samostalno unijeti projekt unutar aplikacije Registar projekata
          <a href="https://projekti.sum.ba" target="_blank">https://projekti.sum.ba</a> prijavom sa svojim eduID korisničkim podacima.
        </div>
        <div class="mt-3">
          <i>Na profilu korisnika moguće je peuzeti životopis u formi kakvu je definirao Ured za osiguranje i unapređenje kvalitete Sveučilišta u Mostaru.</i>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Info"
}
</script>

<style scoped>

</style>