import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import {routes} from './routes';
// import VueAxios from 'vue-axios'
import axios from 'axios'
import qs from "qs";


import './plugins/axios'

Vue.use(require('vue-moment'));

require('@/assets/css/app.css')

// window.Vue = require('vue');
window.axios = require('axios');
// Vue.use(VueAxios, axios);

const base = axios.create({
    baseURL: 'https://api.hrm.sum.ba/',
    withCredentials: true, // Check cross-site Access-Control
    headers: {
        Accept: 'application/json'
    },
    paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'brackets'});
    }
})

Vue.prototype.$http = base

Vue.config.productionTip = false


// axios.interceptors.request.use(function (config) {
//     // console.log(config)
//     const token = localStorage.getItem('token')
//     if (token) {
//         config.headers['Authorization'] = 'Bearer ' + token;
//     }
//     // else{
//     //   window.location = `/login`;
//     // }
//     config.headers.accept = "application/json";
//     return config;
// }, function (error) {
//     return Promise.reject(error);
// });

// const instance = axios.create({
//     baseURL: 'https://api.sum.ba/',
//     withCredentials: true, // Check cross-site Access-Control
//     headers: {
//         Accept: 'application/json'
//     },
//     paramsSerializer: params => {
//         return qs.stringify(params, {arrayFormat: 'brackets'});
//     }
// });

axios.interceptors.response.use(
    function (response) {
        return response;
    }, function (error) {
    const code = parseInt(error.response && error.response.status);
    if (code === 401) {
        window.location.href = `https://api.hrm.sum.ba/sso/login?redirect_to=${location.href}`
    }
    if (code === 403) {
        console.log('Nemate pristup ovom djelu sustava')
    }
    // if(code === 401 || code === 403){
    //   localStorage.setItem("aai_kor_ime", null);
    //   localStorage.setItem("osobni_id", null);
    //   window.location = `/`;
    // }
    return Promise.reject(error);
});

Vue.use(VueRouter)

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
    container: "body",
    duration: 2500,
    offset: -80,
})

const router = new VueRouter({
    routes,
    linkExactActiveClass: "active",
    mode: 'history',
});

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')
