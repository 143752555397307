<template>
  <div>
    <div style="height: 250px" class="hidden-sm-and-down">
      <v-img height="250" :src="require('@/assets/img/background_profiule.png')"></v-img>
      <v-divider></v-divider>
    </div>
    <v-container style="max-width: 90%;">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="4" class="text-center">
              <div :style="$vuetify.breakpoint.smAndDown ? '' : 'margin-top: -220px'">
                <v-skeleton-loader
                    v-if="firstLoad"
                    ref="skeleton"
                    :boilerplate="boilerplate"
                    type="image"
                    :tile="tile"
                    class="mx-auto"
                ></v-skeleton-loader>
                <div v-show="!firstLoad">
                  <v-avatar
                      v-if="profile_img_url !== null"
                      size="250px"
                      class=""
                      style="border: 1px solid #084776; border-radius: 3px; border-radius: 150px;"
                  >
                    <img
                        alt="Avatar"
                        style="object-position: 15% 10%; object-fit: cover;"
                        :src="profile_img_url"/>
                  </v-avatar>
                  <!--                <v-img-->
                  <!--                  class="ma-2"-->
                  <!--                  v-if="profesor.image_path"-->
                  <!--                  :src="'https://api.sum.ba/storage/' + profesor.image_path"-->
                  <!--                ></v-img>-->
                  <v-avatar
                      v-else
                      size="250px"
                      class=""
                      style="background-color: #f2f2f3; border: 1px solid #084776; border-radius: 3px; border-radius: 150px;"
                  >
                    <v-row justify="center" align="center" style="height: 100%">
                      <v-col cols="12" class="text-center">
                        <div class="text-h2 font-weight-medium" style="color: #084776">
                          {{ profesor.ime.charAt(0) + profesor.prezime.charAt(0) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-avatar>
                </div>
              </div>
              <v-skeleton-loader
                  v-if="firstLoad"
                  ref="skeleton"
                  :boilerplate="boilerplate"
                  type="text"
                  :tile="tile"
                  class="mx-auto pt-8"
              ></v-skeleton-loader>
              <div v-show="!firstLoad" class="pt-8">
                <div class="title pa-2" style="color: white; background-color: #084776; border-radius: 3px">

                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" style="color: white">
                        <span v-if="hrm_user !== null">{{
                            hrm_user.akademski_stupanj ? hrm_user.akademski_stupanj : '' }}{{ " " + hrm_user.first_name + " " + hrm_user.last_name
                          }}</span>
                        <span v-else>{{
                            profesor.akad_stupanj_kratica ? profesor.akad_stupanj_kratica : ''
                          }}{{ " " + profesor.ime + " " + profesor.prezime }}</span>
                      </div>
                    </template>
                    <div class="text-caption">Podaci se nalaze u HRM-u</div>
                  </v-tooltip>
                </div>

              </div>
              <div class="pt-5" v-if="hrm_user !== null">
                <v-alert
                    v-if="hrm_user.contract_type_id == 1"
                    outlined
                    color="primary"
                    icon="mdi-information-outline"
                    prominent
                    border="left"
                >
                  <div>Stalno zaposlen na Sveučilištu u Mostaru</div>
                </v-alert>
                <v-alert
                    v-if="hrm_user.contract_type_id == 2"
                    outlined
                    color="primary"
                    icon="mdi-information-outline"
                    prominent
                    border="left"
                >
                  <div>Zaposlen/a na određeno na Sveučilištu u Mostaru</div>
                </v-alert>
                <v-alert
                    v-if="hrm_user.is_external_employee == 1 && hrm_user.contract_type_id != 1"
                    outlined
                    color="primary"
                    icon="mdi-information-outline"
                    prominent
                    border="left"
                >
                  <div>Vanjski suradnik na Sveučilištu u Mostaru</div>
                </v-alert>
                <!--                <p-->
                <!--                    v-if="profesor.radni_odnos == 'D'"-->
                <!--                    style="color: #084776"-->
                <!--                    class="text-center"-->
                <!--                >Stalno zaposlen na Sveučilištu u Mostaru</p>-->
                <!--                <p-->
                <!--                    v-if="profesor.vanjski_suradnik == 'D' && profesor.radni_odnos != 'D'"-->
                <!--                    style="color: #084776"-->
                <!--                    class="text-center"-->
                <!--                >Vanjski suradnik na Sveučilištu u Mostaru</p>-->
              </div>

              <!-- v-if="loggedIn" -->
              <v-btn v-if="hrm_user !== null" color="primary" class="text-none mt-3" outlined @click="exportHTML()">
                <v-icon left>mdi-download-outline</v-icon>
                Preuzmite životopis
              </v-btn>

            </v-col>
            <v-col cols="12" md="8" class="pt-0 mb-4">
              <v-row>
                <v-col cols="12">

                  <v-row>
                    <v-col cols="10">
                      <span class="title">Profil korisnika</span>
                    </v-col>
                    <v-col cols="2" md="1" v-if="loggedIn">
                      <v-dialog v-model="dialog" style="z-index: 999 !important;" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn small fab color="primary" v-on="on">
                            <v-icon color="white" small>mdi-pencil-outline</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="primary">
                            <span style="color: white" class="headline">Uredi profil</span>
                          </v-card-title>
                          <div
                              style="height: 1px; background: linear-gradient(90deg,#fff,#084776,#fff);"
                          ></div>
                          <v-card-text>
                            <v-container v-if="hrm_user !== null">
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                      color="primary"
                                      label="Email"
                                      hide-details
                                      v-model="mail"
                                  ></v-text-field>
                                </v-col>
<!--                                <v-col cols="12">-->
<!--                                  <v-text-field-->
<!--                                      color="primary"-->
<!--                                      label="Broj telefona"-->
<!--                                      hide-details-->
<!--                                      v-model="telefon"-->
<!--                                  ></v-text-field>-->
<!--                                </v-col>-->
                                <v-col cols="12" md="6">
                                  <v-text-field
                                      color="primary"
                                      label="Broj ureda"
                                      hide-details
                                      v-model="office_number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                      color="primary"
                                      label="Lokacija ureda"
                                      hide-details
                                      v-model="office_place"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                      color="primary"
                                      label="ORCID broj"
                                      hide-details
                                      v-model="orcid"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                      color="primary"
                                      label="Google Scholar"
                                      hide-details
                                      v-model="scholar"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                      color="primary"
                                      label="Research Gate"
                                      hide-details
                                      v-model="research_gate"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <!-- <v-textarea
                                    color="primary"
                                    label="Kratki životopis"
                                    hide-details
                                    :value="profesor.description"
                                    v-model="description"
                                  ></v-textarea>-->
                                  <p class="font-weight-bold mb-1">Kratki životopis:</p>
                                  <vue-editor
                                      v-model="description"
                                      color="primary"
                                      name="Kratki životopis"
                                  ></vue-editor>
                                </v-col>
                              </v-row>
                            </v-container>
                            <v-container v-else>
                              <v-row>
                                <v-col cols="12">
                                  <v-alert
                                      outlined
                                      color="warning"
                                      icon="mdi-information-outline"
                                      prominent
                                      border="left"
                                  >
                                    <div>Podatke ćete moći unijeti i promjeniti kada Vaš profil bude kreiran unutar
                                      sustava HRM!
                                    </div>
                                  </v-alert>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <div
                              style="height: 1px; background: linear-gradient(90deg,#fff,#084776,#fff);"
                          ></div>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="red darken-1"
                                outlined
                                text
                                @click="dialog = false"
                            >Zatvori
                            </v-btn>
                            <v-btn
                                v-if="hrm_user !== null"
                                :disabled="loading"
                                color="primary darken-1"
                                outlined
                                text
                                @click="saveChange"
                            >Spremi
                            </v-btn>
                          </v-card-actions>
                          <v-progress-linear :indeterminate="loading"></v-progress-linear>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>

                  <div style="height: 1px; background: linear-gradient(90deg,#fff,#084776,#fff);"></div>
                  <v-row v-if="loading" align="center" justify="center">
                    <v-col cols="12" class="text-center">
                      <v-progress-circular :size="70" :width="7" color="red" indeterminate></v-progress-circular>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="hrm_user !== null">
                    <v-col cols="12" md="4">
                      <v-tooltip top color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-badge color="rgba(255,0,0,0.4)" dot>
                              <div class="sum-color-bold pb-2">
                                <v-icon left color="primary" class="pb-1">mdi-school-outline</v-icon>
                                Zvanje
                              </div>
                            </v-badge>
                          </div>
                        </template>
                        <div class="text-caption">Podatak se nalazi u HRM-u</div>
                      </v-tooltip>
                      <v-skeleton-loader
                          v-if="firstLoad"
                          ref="skeleton"
                          :boilerplate="boilerplate"
                          type="text"
                          :tile="tile"
                          class="mx-auto"
                      ></v-skeleton-loader>
                      <p v-show="!firstLoad" class="mb-1" v-if="hrm_user.zvanje">{{ hrm_user.zvanje }}</p>
                      <p v-else>---</p>
                      <p v-show="!firstLoad" style="font-size: 13px" class="font-italic" v-if="hrm_user.datum_zadnjeg_izbora">
                        Datum
                        zadnjeg izbora u zvanje {{ hrm_user.datum_zadnjeg_izbora }}</p>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-tooltip top color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-badge color="rgba(255,0,0,0.4)" dot>
                              <div class="sum-color-bold pb-2">
                                <v-icon left color="primary" class="pb-1">mdi-home-city-outline</v-icon>
                                Matična ustanova
                              </div>
                            </v-badge>
                          </div>
                        </template>
                        <div class="text-caption">Podatak se nalazi u HRM-u</div>
                      </v-tooltip>
                      <v-skeleton-loader
                          v-if="firstLoad"
                          ref="skeleton"
                          :boilerplate="boilerplate"
                          type="text"
                          :tile="tile"
                          class="mx-auto"
                      ></v-skeleton-loader>
                      <p v-show="!firstLoad" v-if="hrm_user.organisation">{{ hrm_user.organisation.maticna_ustanova }}</p>
                      <p v-show="!firstLoad" v-else>---</p>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-tooltip top color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-badge color="rgba(255,0,0,0.4)" dot>
                              <div class="sum-color-bold pb-2">
                                <v-icon left color="primary" class="pb-1">mdi-book-education-outline</v-icon>
                                Dodatno raspoređen
                              </div>
                            </v-badge>
                          </div>
                        </template>
                        <div class="text-caption">Podatak se nalazi u HRM-u</div>
                      </v-tooltip>
                      <v-skeleton-loader
                          v-if="firstLoad"
                          ref="skeleton"
                          :boilerplate="boilerplate"
                          type="text"
                          :tile="tile"
                          class="mx-auto"
                      ></v-skeleton-loader>
                      <p v-show="!firstLoad" v-if="hrm_user.dodatno_rasporeden">{{ hrm_user.dodatno_rasporeden }}</p>
                      <p v-show="!firstLoad" v-else>---</p>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-tooltip top color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-badge color="rgba(255,0,0,0.4)" dot>
                              <div class="sum-color-bold pb-2">
                                <v-icon left color="primary" class="pb-1">mdi-book-outline</v-icon>
                                Matični studij
                              </div>
                            </v-badge>
                          </div>
                        </template>
                        <div class="text-caption">Podatak se nalazi u HRM-u</div>
                      </v-tooltip>
                      <v-skeleton-loader
                          v-if="firstLoad"
                          ref="skeleton"
                          :boilerplate="boilerplate"
                          type="text"
                          :tile="tile"
                          class="mx-auto"
                      ></v-skeleton-loader>
                      <p v-show="!firstLoad" v-if="hrm_user.study">{{ hrm_user.study.title }}</p>
                      <p v-show="!firstLoad" v-else>---</p>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-tooltip top color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-badge color="rgba(255,0,0,0.4)" dot>
                              <div class="sum-color-bold pb-2">
                                <v-icon left color="primary" class="pb-1">mdi-email-outline</v-icon>
                                Službeni email
                              </div>
                            </v-badge>
                          </div>
                        </template>
                        <div class="text-caption">Podatak se nalazi u HRM-u</div>
                      </v-tooltip>
                      <v-skeleton-loader
                          v-if="firstLoad"
                          ref="skeleton"
                          :boilerplate="boilerplate"
                          type="text"
                          :tile="tile"
                          class="mx-auto"
                      ></v-skeleton-loader>
                      <p v-show="!firstLoad" v-if="hrm_user.email">{{ hrm_user.email }}</p>
                      <p v-else>---</p>
                    </v-col>
<!--                    <v-col cols="12" md="4">-->
<!--                      <v-tooltip top color="primary">-->
<!--                        <template v-slot:activator="{ on, attrs }">-->
<!--                          <div v-bind="attrs" v-on="on">-->
<!--                            <v-badge color="rgba(8,71,118,0.6)" dot>-->
<!--                              <div class="sum-color-bold pb-2">-->
<!--                                <v-icon left color="primary" class="pb-1">mdi-email-outline</v-icon>-->
<!--                                Privatni email-->
<!--                              </div>-->
<!--                            </v-badge>-->
<!--                          </div>-->
<!--                        </template>-->
<!--                        <div class="text-caption">Podatak je moguće promjeniti unutar CV aplikacije</div>-->
<!--                      </v-tooltip>-->
<!--                      <v-skeleton-loader-->
<!--                          v-if="firstLoad"-->
<!--                          ref="skeleton"-->
<!--                          :boilerplate="boilerplate"-->
<!--                          type="text"-->
<!--                          :tile="tile"-->
<!--                          class="mx-auto"-->
<!--                      ></v-skeleton-loader>-->
<!--                      <p v-show="!firstLoad" v-if="hrm_user.email_private">{{ hrm_user.email_private }}</p>-->
<!--                      <p v-else>-&#45;&#45;</p>-->
<!--                    </v-col>-->
<!--                    <v-col cols="12" md="4">-->
<!--                      <v-tooltip top color="primary">-->
<!--                        <template v-slot:activator="{ on, attrs }">-->
<!--                          <div v-bind="attrs" v-on="on">-->
<!--                            <v-badge color="rgba(8,71,118,0.6)" dot>-->
<!--                              <div class="sum-color-bold pb-2">-->
<!--                                <v-icon left color="primary" class="pb-1">mdi-cellphone</v-icon>-->
<!--                                Broj telefona-->
<!--                              </div>-->
<!--                            </v-badge>-->
<!--                          </div>-->
<!--                        </template>-->
<!--                        <div class="text-caption">Podatak je moguće promjeniti unutar CV aplikacije</div>-->
<!--                      </v-tooltip>-->
<!--                      <v-skeleton-loader-->
<!--                          v-if="firstLoad"-->
<!--                          ref="skeleton"-->
<!--                          :boilerplate="boilerplate"-->
<!--                          type="text"-->
<!--                          :tile="tile"-->
<!--                          class="mx-auto"-->
<!--                      ></v-skeleton-loader>-->
<!--                      <p v-show="!firstLoad" v-if="hrm_user.mobile">{{ hrm_user.mobile }}</p>-->
<!--                      <p v-else>-&#45;&#45;</p>-->
<!--                    </v-col>-->
                    <v-col cols="12" md="4">
                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-badge color="rgba(8,71,118,0.6)" dot>
                              <div class="sum-color-bold pb-2">
                                <v-icon left color="primary" class="pb-1">mdi-door</v-icon>
                                Broj ureda
                              </div>
                            </v-badge>
                          </div>
                        </template>
                        <div class="text-caption">Podatak je moguće promjeniti unutar CV aplikacije</div>
                      </v-tooltip>
                      <v-skeleton-loader
                          v-if="firstLoad"
                          ref="skeleton"
                          :boilerplate="boilerplate"
                          type="text"
                          :tile="tile"
                          class="mx-auto"
                      ></v-skeleton-loader>
                      <p
                          v-show="!firstLoad"
                          v-if="hrm_user.office_number"
                      >{{ hrm_user.office_number }}</p>
                      <p v-else>---</p>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-badge color="rgba(8,71,118,0.6)" dot>
                              <div class="sum-color-bold pb-2">
                                <v-icon left color="primary" class="pb-1">mdi-map-marker-outline</v-icon>
                                Lokacija
                              </div>
                            </v-badge>
                          </div>
                        </template>
                        <div class="text-caption">Podatak je moguće promjeniti unutar CV aplikacije</div>
                      </v-tooltip>
                      <v-skeleton-loader
                          v-if="firstLoad"
                          ref="skeleton"
                          :boilerplate="boilerplate"
                          type="text"
                          :tile="tile"
                          class="mx-auto"
                      ></v-skeleton-loader>
                      <p v-show="!firstLoad" v-if="hrm_user.office_place">{{ hrm_user.office_place }}</p>
                      <p v-show="!firstLoad" v-else>---</p>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-badge color="rgba(8,71,118,0.6)" dot>
                              <div class="sum-color-bold pb-2">
                                <v-icon left color="primary" class="pb-1">mdi-numeric</v-icon>
                                ORCID broj
                              </div>
                            </v-badge>
                          </div>
                        </template>
                        <div class="text-caption">Podatak je moguće promjeniti unutar CV aplikacije</div>
                      </v-tooltip>
                      <v-skeleton-loader
                          v-if="firstLoad"
                          ref="skeleton"
                          :boilerplate="boilerplate"
                          type="text"
                          :tile="tile"
                          class="mx-auto"
                      ></v-skeleton-loader>
                      <p v-show="!firstLoad" v-if="hrm_user.orcid">{{ hrm_user.orcid }}</p>
                      <p v-show="!firstLoad" v-else>---</p>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-badge color="rgba(8,71,118,0.6)" dot>
                              <div class="sum-color-bold pb-2">
                                <v-icon left color="primary" class="pb-1">mdi-google</v-icon>
                                Google Scholar
                              </div>
                            </v-badge>
                          </div>
                        </template>
                        <div class="text-caption">Podatak je moguće promjeniti unutar CV aplikacije</div>
                      </v-tooltip>
                      <v-skeleton-loader
                          v-if="firstLoad"
                          ref="skeleton"
                          :boilerplate="boilerplate"
                          type="text"
                          :tile="tile"
                          class="mx-auto"
                      ></v-skeleton-loader>
                      <v-btn v-show="!firstLoad"
                             v-if="hrm_user.google_scholar_link && hrm_user.google_scholar_link != 'null'"
                             :href="hrm_user.google_scholar_link"
                             target="_blank" outlined rounded class="text-none" small>
                        <v-icon left small>mdi-open-in-new</v-icon>
                        Poveznica na Scholar
                      </v-btn>
                      <p v-else>---</p>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-badge color="rgba(8,71,118,0.6)" dot>
                              <div class="sum-color-bold pb-2">
                                <v-icon left color="primary" class="pb-1">mdi-alpha-r-circle-outline</v-icon>
                                Research Gate
                              </div>
                              <!--                          <div class="sum-color-bold pb-2">-->
                              <!--                            <v-row>-->
                              <!--                              <v-col cols="2" class="pr-0 pt-0 pb-0">-->
                              <!--                                <v-img-->
                              <!--                                    style="width: 17px; height: 19px"-->
                              <!--                                    :src="require('@/assets/img/rg.png')"-->
                              <!--                                ></v-img>-->
                              <!--                              </v-col>-->
                              <!--                              <v-col-->
                              <!--                                  cols="10"-->
                              <!--                                  class="pt-0 pb-0"-->
                              <!--                                  style="margin-left: -20px"-->
                              <!--                              >Research Gate-->
                              <!--                              </v-col>-->
                              <!--                            </v-row>-->
                              <!--                          </div>-->
                            </v-badge>
                          </div>
                        </template>
                        <div class="text-caption">Podatak je moguće promjeniti unutar CV aplikacije</div>
                      </v-tooltip>
                      <v-skeleton-loader
                          v-if="firstLoad"
                          ref="skeleton"
                          :boilerplate="boilerplate"
                          type="text"
                          :tile="tile"
                          class="mx-auto"
                      ></v-skeleton-loader>
                      <v-btn v-show="!firstLoad"
                             v-if="hrm_user.research_gate_link && hrm_user.research_gate_link != 'null'"
                             :href="hrm_user.research_gate_link"
                             target="_blank" outlined rounded class="text-none" small>
                        <v-icon left small>mdi-open-in-new</v-icon>
                        Poveznica na Research Gate
                      </v-btn>
                      <p v-else>---</p>
                    </v-col>

                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <v-alert
                          outlined
                          color="error"
                          icon="mdi-information-outline"
                          prominent
                          border="left"
                      >
                        <div>Ukoliko nema podataka o korisniku, potrebno je podatke unijeti u HRM sustav. Za sve
                          informacije obratite se <a href="https://eduid.sum.ba/koordinatori#koordinatori"
                                                     target="_blank" class="error--text">SUMIT koordinatoru</a> s Vaše
                          ustrojbene jedinice.
                        </div>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div style="height: 1px; background: linear-gradient(90deg,#fff,#084776,#fff);"></div>
        </v-col>
        <v-col cols="12">
          <v-tabs v-model="tab" show-arrows centered fixed-tabs icons-and-text slider-size="3" height="100">
            <v-tabs-slider color="#E20E0E"></v-tabs-slider>
            <v-tab v-for="(tab, i) in list_tabs" :key="i" :href="'#tab-' + (i+1)">
              <v-tooltip bottom
                         :color="tab.id === 1 ? 'rgba(8,71,118,0.8)' : tab.id === 2 ? 'rgba(8,118,8,0.8)' : 'rgba(118,41,0,0.8)'">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-badge
                        style="margin-bottom: -2px"
                        :color="tab.id === 1 ? 'rgba(8,71,118,0.7)' : tab.id === 2 ? 'rgba(8,118,8,0.4)' : 'rgba(118,41,0,0.6)'"
                        dot>
                      <v-icon size="30" class="pb-2">{{ tab.icon }}</v-icon>
                      <br>
                      {{ tab.text }}
                    </v-badge>
                  </div>
                </template>
                <div class="text-caption">
                  {{ tab.id === 1 ? 'Podatak je moguće promjeniti unutar CV aplikacije' : tab.id === 2 ? 'Radovi se unose u Registru Sveučilišnih radova' : 'Projekti se unose u Registru Sveučilišnih projekata' }}
                </div>
              </v-tooltip>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, i) in list_tabs" :key="i" :value="'tab-' + (i+1)">
              <v-row v-if="item.text == 'Kratki životopis'">
                <v-col cols="12" v-if="hrm_user !== null">
                  <v-row class="pa-0 ma-0">
                    <v-col cols="12" v-if="hrm_user.biography" class="pt-4">
                      <p class="headline sum-color-bold">Kratki životopis</p>
                      <p v-html="hrm_user.biography"></p>
                    </v-col>
                    <v-col cols="12" v-else>
                      <v-alert outlined type="warning" prominent border="left">Ništa za prikazati!</v-alert>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-else>
                  <v-alert outlined type="warning" prominent border="left">Ovaj podatak ćete moći unijeti i promjeniti
                    kada Vaš profil bude kreiran unutar sustava HRM!
                  </v-alert>
                </v-col>
              </v-row>
              <v-row v-if="item.text == 'Objavljeni radovi'">
                <v-col id="radovi" cols="12" class="pt-4">
                  <p class="headline sum-color-bold">Objavljeni radovi</p>
                  <!-- <p>{{profesor.zvanja}}</p> -->
                  <v-row>
                    <v-col cols="12" v-if="profesor.znanstveni_radovi && Object.keys(profesor.znanstveni_radovi).length">
                      <v-expansion-panels focusable>
                        <v-expansion-panel v-for="(rad, i) in profesor.znanstveni_radovi" :key="i">
                          <v-expansion-panel-header>
                            <v-row>
                              <v-col cols="12" sm="10">
                                <v-row>
                              <v-col cols="12" class="text-left">
                                <h3 style="color: #125A92">{{ rad.naslov }}</h3>
                              </v-col>
                              <v-col cols="4" class="text--secondary" v-if="rad.vrsta_rada">
                                <div
                                    class="red-box-small text-center pt-2"
                                    v-if="rad.vrsta_rada.naziv == 'Članak u časopisu'"
                                >
                                  <span>{{ rad.vrsta_rada.naziv }}</span>
                                </div>
                                <div
                                    class="yellow-box-small text-center pt-2"
                                    v-else-if="rad.vrsta_rada.naziv == 'Članak/poglavlje u knjizi'"
                                >
                                  <span>{{ rad.vrsta_rada.naziv }}</span>
                                </div>
                                <div
                                    class="red2-box-small text-center pt-2"
                                    v-else-if="rad.vrsta_rada.naziv == 'Članak u zborniku'"
                                >
                                  <span>{{ rad.vrsta_rada.naziv }}</span>
                                </div>
                                <div
                                    class="green-box-small text-center pt-2"
                                    v-else-if="rad.vrsta_rada.naziv == 'Skripta'"
                                >
                                  <span>{{ rad.vrsta_rada.naziv }}</span>
                                </div>
                                <div
                                    class="pink-box-small text-center pt-2"
                                    v-else-if="rad.vrsta_rada.naziv == 'Knjiga'"
                                >
                                  <span>{{ rad.vrsta_rada.naziv }}</span>
                                </div>
                                <div class="blue-box-small text-center pt-2" v-else>
                                  <span>{{ rad.vrsta_rada.naziv }}</span>
                                </div>
                              </v-col>
                              <v-col cols="2" class="text--secondary mt-2">
                                <span>{{ rad.godina }}</span>
                              </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" sm="2">
                                <v-btn small :href="'https://pub.sum.ba/radovi/' + rad.id" target="_blank" class="mt-6 text-none" rounded outlined>
                                  <v-icon left small>mdi-open-in-new</v-icon>
                                  Pregledaj rad
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div class="pa-4" v-if="rad.sazetak">{{ rad.sazetak }}</div>
                            <div class="pa-4" v-else>
                              <v-alert outlined type="info" prominent border="left">Nije unesen sažetak rada!</v-alert>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                    <v-col cols="12" v-else>
                      <v-alert outlined type="warning" prominent border="left">Ništa za prikazati!</v-alert>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else-if="item.text == 'Projekti'">
                <v-col id="projekti" cols="12">
                  <p class="headline sum-color-bold">Projekti</p>
                  <v-row>
                    <v-col
                        cols="12"
                        v-if="profesor.projekti && Object.keys(profesor.projekti).length"
                    >
                      <v-expansion-panels focusable>
                        <v-expansion-panel v-for="(projekt, i) in profesor.projekti" :key="i">
                          <v-expansion-panel-header>
                            <v-row>
                              <v-col cols="12" md="7" class="text-left">
                                <v-row>
                                  <v-col cols="12" class="pb-0">
                                    <p class="primary--text title mb-0 pb-0">{{ projekt.title }}</p>
                                  </v-col>
                                  <v-col cols="12">
                                    <b>Voditelj projekta:</b>
                                    {{ projekt.leader }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" md="3" class="text--secondary text-center">
                                <div class="blue-box-small text-center pt-2 mt-4">
                                  <span>{{ projekt.fakultet.name }}</span>
                                </div>
                                <div class="mt-5">
                                  <p
                                      v-if="projekt.classification == 'scientific'"
                                      style="color: #952A00"
                                  >Znanstveni projekt</p>
                                  <p
                                      v-else-if="projekt.classification == 'expert'"
                                      style="color: #956F00"
                                  >Stručni projekt</p>
                                </div>
                              </v-col>
                              <v-col cols="12" md="2" class="text--secondary mt-6">
                                <span>
                                  <b>{{ projekt.start_year }}</b>
                                </span>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-card>
                              <!-- <v-card-title
                                class="headline"
                                primary-title
                              >
                                {{projekt.title}}
                              </v-card-title>-->

                              <v-card-text>
                                <v-row>
                                  <v-col cols="4">
                                    <p>
                                      <b>Naziv projekta</b>
                                    </p>
                                  </v-col>
                                  <v-divider vertical></v-divider>
                                  <v-col cols="6" class="pl-2">
                                    <p>{{ projekt.title }}</p>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>
                                  <v-col cols="4">
                                    <p>
                                      <b>Voditelj/koordinator sa SUM</b>
                                    </p>
                                  </v-col>
                                  <v-divider vertical></v-divider>
                                  <v-col cols="6" class="pl-2">
                                    <p>{{ projekt.leader }}</p>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>
                                  <v-col cols="4">
                                    <p>
                                      <b>Članovi (partneri)</b>
                                    </p>
                                  </v-col>
                                  <v-divider vertical></v-divider>
                                  <v-col cols="6" class="pl-2">
                                    <div v-for="(item, i) in projekt.clanovi" :key="i">
                                      <p>{{ item.ime + " " + item.prezime }}</p>
                                    </div>
                                    <p>{{ projekt.members }}</p>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>
                                  <v-col cols="4">
                                    <p>
                                      <b>Godina početka</b>
                                    </p>
                                  </v-col>
                                  <v-divider vertical></v-divider>
                                  <v-col cols="6" class="pl-2">
                                    <p>{{ projekt.start_year }}</p>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>
                                  <v-col cols="4">
                                    <p>
                                      <b>Trajanje (mjeseci)</b>
                                    </p>
                                  </v-col>
                                  <v-divider vertical></v-divider>
                                  <v-col cols="6" class="pl-2">
                                    <p>{{ projekt.length_months }}</p>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>
                                  <v-col cols="4">
                                    <p>
                                      <b>Status</b>
                                    </p>
                                  </v-col>
                                  <v-divider vertical></v-divider>
                                  <v-col cols="6" class="pl-2">
                                    <p v-if="projekt.status == 'done'">Završen</p>
                                    <p v-else-if="projekt.status == 'in_progress'">U tijeku</p>
                                    <p v-else-if="projekt.status == 'waiting'">Čeka</p>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>
                                  <v-col cols="4">
                                    <p>
                                      <b>Klasifikacija</b>
                                    </p>
                                  </v-col>
                                  <v-divider vertical></v-divider>
                                  <v-col cols="6" class="pl-2">
                                    <p
                                        v-if="projekt.classification == 'scientific'"
                                    >Znanstveni projekt</p>
                                    <p
                                        v-else-if="projekt.classification == 'expert'"
                                    >Stručni projekt</p>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>
                                  <v-col cols="4">
                                    <p>
                                      <b>Područje istraživanja</b>
                                    </p>
                                  </v-col>
                                  <v-divider vertical></v-divider>
                                  <v-col cols="6" class="pl-2">
                                    <p v-if="projekt.area == 'natural'">Prirodne znanosti</p>
                                    <p
                                        v-else-if="projekt.area == 'biomedical'"
                                    >Biomedicina i zdravstvo</p>
                                    <p v-else-if="projekt.area == 'technical'">Tehničke znanosti</p>
                                    <p v-else-if="projekt.area == 'social'">Društvene znanosti</p>
                                    <p v-else-if="projekt.area == 'other'">Ostalo</p>
                                    <p
                                        v-else-if="projekt.area == 'interdisciplinary'"
                                    >Interdisciplinarno područje</p>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>
                                  <v-col cols="4">
                                    <p>
                                      <b>Opis</b>
                                    </p>
                                  </v-col>
                                  <v-divider vertical></v-divider>
                                  <v-col cols="6" class="pl-2">
                                    <p>{{ projekt.desc }}</p>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>
                                  <v-col cols="4">
                                    <p>
                                      <b>WEB stranica</b>
                                    </p>
                                  </v-col>
                                  <v-divider vertical></v-divider>
                                  <v-col cols="6" class="pl-2">
                                    <p>{{ projekt.web }}</p>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <!-- <v-row >
                                  <v-col cols="4"><p><b>Izvor financiranja</b></p></v-col><v-divider vertical></v-divider><v-col cols="6" class="pl-2"><p>{{projekt.source}}</p></v-col>
                                </v-row>-->
                                <v-divider></v-divider>
                                <v-row>
                                  <v-col cols="4">
                                    <p>
                                      <b>Ustanova</b>
                                    </p>
                                  </v-col>
                                  <v-divider vertical></v-divider>
                                  <v-col cols="6">
                                    <p>{{ projekt.fakultet.name }}</p>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                    <v-col cols="12" v-else>
                      <v-alert outlined type="warning" prominent border="left">Ništa za prikazati!</v-alert>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else-if="item.text == 'Nastava'">
                <v-col id="nastava" cols="12" class="pt-4">
                  <p class="headline sum-color-bold">Nastava</p>
                  <div v-if="profesor.predmeti && Object.keys(profesor.predmeti).length">
                    <v-expansion-panels focusable v-if="loggedIn">
                      <v-expansion-panel v-for="(predmet, i) in profesor.predmeti" :key="i">
                        <v-expansion-panel-header>
                          <v-row>
                            <v-col cols="12" class="text-left">{{ i }}</v-col>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <!-- <v-row v-if="loggedIn">
                            <v-col cols="12" md="4">
                              <p class="mb-0 mt-1"><b>Web vidljivost kolegija:</b></p>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-btn color="#084776" @click="showItem(predmet)" dark>Vidljivost</v-btn>
                              <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
                              
                              <v-card>
                                <v-card-text>{{vidljivost}}</v-card-text>
                              </v-card>
                              </v-menu>
                            </v-col>
                          </v-row>-->
                          <!-- <p class="title primary--text">Studijske grupe</p> -->
                          <v-row>
                            <v-col cols="5">
                              <p class="mb-0"><b>Naziv predmeta i šifra</b></p>
                            </v-col>
                            <v-col cols="5">
                              <p class="mb-0"><b>Sastavnica</b></p>
                            </v-col>
                            <v-col cols="2">
                              <p class="mb-0"><b>Vidljivost</b></p>
                            </v-col>
                          </v-row>
                          <!-- {{predmet}} -->
                          <div v-for="(item, j) in predmet" :key="j">
                            <v-row>
                              <v-col cols="5">
                                <p class="mb-0">{{ item.naziv }}</p>
                                <span class="grey--text">{{ item.sifra }}</span>
                              </v-col>
                              <v-col cols="5">
                                <p class="mb-0">{{ item.sastavnica }}</p>
                              </v-col>
                              <v-col cols="2">
                                <v-checkbox class="ma-0" hide-details v-model="item.vidljivost"></v-checkbox>
                              </v-col>
                            </v-row>

                            <!-- <div v-for="(studij, k) in item.studiji" :key="k">
                              <span>{{studij}}</span>
                            </div> -->
                          </div>
                          <v-row>
                            <v-spacer></v-spacer>
                            <v-btn color="#084776" dark :loading="loading" @click="changeVisibility(predmet)">Spremi
                            </v-btn>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels focusable v-else>
                      <v-expansion-panel v-for="(predmet, i) in profesor.predmeti" :key="i">
                        <v-expansion-panel-header>
                          <v-row>
                            <v-col cols="12" class="text-left">{{ i }}</v-col>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row>
                            <v-col cols="6">
                              <p class="mb-0"><b>Naziv predmeta i šifra</b></p>
                            </v-col>
                            <v-col cols="6">
                              <p class="mb-0"><b>Sastavnica</b></p>
                            </v-col>
                          </v-row>
                          <div v-for="(item, j) in predmet" :key="j">
                            <v-row v-if="item.vidljivost">
                              <v-col cols="6">
                                <p class="mb-0">{{ item.naziv }}</p>
                                <span class="grey--text">{{ item.sifra }}</span>
                              </v-col>
                              <v-col cols="6">
                                <p class="mb-0">{{ item.sastavnica }}</p>
                              </v-col>
                            </v-row>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <div v-else>
                    <v-alert outlined type="warning" prominent border="left">Ništa za prikazati!</v-alert>
                  </div>
                </v-col>
              </v-row>
              <!-- <v-card flat v-if="item.text == 'Objavljeni radovi'">
                <v-card-text>testni text</v-card-text>
              </v-card>-->
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" md="4">
          <v-card>
            <div style="background-color: grey; height: 120px"></div>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-avatar size="120" class="image-border">
                  <v-img
                    v-if="profesor.image_path"
                    :src="'https://api.sum.ba/storage/' + profesor.image_path"
                  ></v-img>
                  <v-img v-else :src="require('@/assets/img/image.png')"></v-img>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center pb-0 mb-0">
                <p
                  class="title mb-0 pb-0"
                  style="color: #084776"
                >{{profesor.akad_stupanj_kratica + " " + profesor.ime + " " + profesor.prezime}}</p>
              </v-col>
              <v-col cols="12" class="text-center">
                <p class="subtitle" style="color: #084776">{{profesor.zvanje}}</p>
              </v-col>
              <v-col cols="12" class="text-center mt-0 pt-0">
                <p style="color: #084776">
                  E-mail:
                  <a :href="'mailto:' + profesor.e_mail">{{profesor.e_mail}}</a>
                </p>
              </v-col>
            </v-row>
            <div style="height: 1px; background: linear-gradient(90deg,#fff,#084776,#fff);"></div>
            <v-row class="pr-3 pl-3">
              <v-col cols="12" class="text-center mb-0 pb-0">
                <p class="mb-0 pb-0" style="color: #084776">
                  <v-icon color="primary" small left>fas fa-university</v-icon>
                  <strong>Matična ustanova</strong>
                </p>
              </v-col>
              <v-col cols="12" class="text-center">
                <p style="color: #084776">{{profesor.maticna_ustanova}}</p>
              </v-col>

              <v-col
                cols="12"
                class="text-center mb-0 pb-0 mt-0 pt-0"
                v-if="profesor.maticni_studij != null"
              >
                <p class="mb-0 pb-0" style="color: #084776">
                  <strong>Matični studij</strong>
                </p>
              </v-col>
              <v-col cols="12" class="text-center" v-if="profesor.maticni_studij != null">
                <p style="color: #084776">{{profesor.maticni_studij}}</p>
              </v-col>

              <v-col cols="12" class="mt-0 pt-0">
                <p
                  v-if="profesor.radni_odnos == 'D'"
                  style="color: #084776"
                  class="text-center"
                >Stalno zaposlen na Sveučilištu u Mostaru</p>
                <p
                  v-if="profesor.vanjski_suradnik == 'D' && profesor.radni_odnos != 'D'"
                  style="color: #084776"
                  class="text-center"
                >Vanjski suradnik na Sveučilištu u Mostaru</p>
              </v-col>
            </v-row>
          </v-card>
          <v-row class="pt-4">
            <v-col cols="12">
              <v-btn outlined color="primary" width="100%" @click="getView('osnovne')" left>
                <v-icon left>far fa-address-card</v-icon>Osnovne informacija
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn outlined color="primary" width="100%" @click="getView('radovi')" left>
                <v-icon left>far fa-edit</v-icon>Objavljeni radovi
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn outlined color="primary" width="100%" @click="getView('projekti')" left>
                <v-icon left>fas fa-book</v-icon>Projekti
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn outlined color="primary" width="100%" @click="getView('nastava')" left>
                <v-icon left>far fa-clock</v-icon>Nastava
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col id="top" cols="12" md="8" v-if="informacije == 'osnovne'">
          <v-row>
            <v-col cols="12" md="6">
              <p class="headline sum-color-bold">Osnovne informacije</p>
            </v-col>
            <v-col cols="10" md="5" v-if="loggedIn">
              <v-btn color="primary" outlined @click="exportHTML()">Preuzmite životopis</v-btn>
            </v-col>
            <v-col cols="2" md="1" v-if="loggedIn">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn small fab color="primary" v-on="on" @click="update()">
                    <v-icon color="white" small>fas fa-pencil-alt</v-icon>
                  </v-btn>
                </template>
                <span>{{tooltip_text}}</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <div
            class="mt-4"
            style="height: 1px; background: linear-gradient(90deg,#fff,#084776,#fff);"
          ></div>

          <v-row class="pt-3">
            <v-col cols="12" md="6">
              <div class="sum-color-bold pb-2">
                <v-icon left small color="primary" class="pb-1">fas fa-envelope</v-icon>Email adresa:
              </div>
              <v-text-field
                single-line
                :disabled="!disabled"
                outlined
                label="Email adresa"
                :value="mail"
                hide-details
                v-model="mail"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <div class="sum-color-bold pb-2">
                <v-icon left small color="primary" class="pb-1">fas fa-graduation-cap</v-icon>Zvanje:
              </div>
              <v-text-field
                hide-details
                single-line
                disabled
                outlined
                label="Google Scholar"
                :value="profesor.zvanje"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <div class="sum-color-bold pb-2">
                <v-icon left small color="primary" class="pb-1">fab fa-google</v-icon>Google Scholar:
              </div>
              <v-text-field
                v-if="loggedIn"
                single-line
                :disabled="!disabled"
                outlined
                label="Google Scholar"
                :value="profesor.scholar"
                hide-details
                v-model="scholar"
              ></v-text-field>
              <div v-else class="py-4 px-2 box-border">
                <a :href="profesor.scholar" target="_blank">Link na Scholar</a>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <div class="sum-color-bold pb-2">
                <v-row>
                  <v-col cols="1" class="pr-0 pt-0 pb-0">
                    <v-img width="95%" :src="require('@/assets/img/rg.png')"></v-img>
                  </v-col>
                  <v-col cols="11" class="pt-0 pb-0">Research Gate:</v-col>
                </v-row>
              </div>
              <v-text-field
                single-line
                :disabled="!disabled"
                outlined
                label="Research Gate"
                :value="profesor.website"
                hide-details
                v-model="research_gate"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <div class="sum-color-bold pb-2">
                <v-icon left small color="primary" class="pb-1">fas fa-door-open</v-icon>Broj ureda:
              </div>
              <v-text-field
                single-line
                :disabled="!disabled"
                outlined
                label="Broj ureda"
                :value="office"
                hide-details
                v-model="office"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <div class="sum-color-bold pb-2">
                <v-icon left small color="primary" class="pb-1">fas fa-phone-square</v-icon>Broj telefona:
              </div>
              <v-text-field single-line disabled outlined label="Broj telefona" hide-details></v-text-field>
            </v-col>
          </v-row>

          <div v-if="loggedIn">
            <div class="sum-color-bold pb-2">
              <v-icon left small color="primary" class="pb-1">far fa-id-card</v-icon>Kratki životopis
            </div>

            <v-textarea
              outlined
              single-line
              :disabled="!disabled"
              label="Kratki životopis"
              :value="profesor.description"
              v-model="description"
              hide-details
            ></v-textarea>
          </div>
          <div v-else-if="profesor.description != null" class="pt-3">
            <div class="sum-color-bold pb-2">
              <v-icon left small color="primary" class="pb-1">far fa-id-card</v-icon>Kratki životopis
            </div>

            <v-textarea
              outlined
              single-line
              disabled
              label="Kratki životopis"
              :value="profesor.description"
            ></v-textarea>
          </div>
          <v-row>
            <v-col v-if="loggedIn" cols="12" class="pt-4">
              <div class="sum-color-bold pb-2">Učitajte Vašu sliku:</div>
              <v-file-input
                :disabled="!disabled"
                color="primary"
                outlined
                :rules="rules"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Klinknite za učitavanje"
                prepend-icon="mdi-camera"
                single-line
                ref="fileInput"
                v-model="fileInput"
                hide-details
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row v-if="loggedIn">
            <v-col cols="12" md="4">
              <v-btn
                :disabled="!disabled"
                width="100%"
                @click="save()"
                color="primary"
              >Spremi promjene</v-btn>
            </v-col>
          </v-row>
          <div
            class="mt-4"
            style="height: 1px; background: linear-gradient(90deg,#fff,#084776,#fff);"
          ></div>
          <p class="title sum-color-bold pt-4">Izbori u zvanje</p>
          <div v-for="(zvanje, i) in profesor.zvanja" :key="i">
            <v-row>
              <v-col cols="4">
                <div class="blue-box text-center pt-2">{{zvanje.datum_primanja.slice(0,10)}}</div>
              </v-col>
              <v-col cols="8">
                <div class="pt-2">{{zvanje.naziv}}</div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col id="radovi" cols="12" md="8" v-else-if="informacije == 'radovi'">
          <p class="headline sum-color-bold">Objavljeni radovi</p>
          <v-row>
            <v-col cols="12">
              <v-expansion-panels focusable>
                <v-expansion-panel v-for="(rad, i) in profesor.radovi" :key="i">
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col cols="12" class="text-left">{{rad.naslov}}</v-col>
                      <v-col cols="4" class="text--secondary">
                        <div class="blue-box-small text-center pt-1">
                          <span>{{rad.tip_rada}}</span>
                        </div>
                      </v-col>
                      <v-col cols="2" class="text--secondary">
                        <span>{{rad.godina}}</span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>{{rad.abstract}}</v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
        <v-col id="projekti" cols="12" md="8" v-else-if="informacije == 'projekti'">
          <p class="headline sum-color-bold">Projekti</p>
          <v-row>
            <v-col cols="12">
              <v-expansion-panels focusable>
                <v-expansion-panel v-for="(projekt, i) in profesor.projekti" :key="i">
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col cols="4" class="text--secondary">
                        <div class="blue-box-small text-center pt-1 mt-4">
                          <span>{{projekt.fakultet.name}}</span>
                        </div>
                      </v-col>
                      <v-col cols="6" class="text-left">
                        <v-row>
                          <v-col cols="12" class="pb-0">
                            <p class="primary--text title mb-0 pb-0">{{projekt.title}}</p>
                          </v-col>
                          <v-col cols="12">
                            <b>Voditelj projekta:</b>
                            {{profesor.ime + " " + profesor.prezime}}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="text--secondary mt-4">
                        <span>{{projekt.start_year}}</span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card>

                      <v-card-text>
                        <v-row>
                          <v-col cols="4">
                            <p>
                              <b>Naziv projekta</b>
                            </p>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="6" class="pl-2">
                            <p>{{projekt.title}}</p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col cols="4">
                            <p>
                              <b>Voditelj/koordinator sa SUM</b>
                            </p>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="6" class="pl-2">
                            <p>{{profesor.ime + ' ' + profesor.prezime}}</p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col cols="4">
                            <p>
                              <b>Godina početka</b>
                            </p>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="6" class="pl-2">
                            <p>{{projekt.start_year}}</p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col cols="4">
                            <p>
                              <b>Trajanje (mjeseci)</b>
                            </p>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="6" class="pl-2">
                            <p>{{projekt.length_months}}</p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col cols="4">
                            <p>
                              <b>Status</b>
                            </p>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="6" class="pl-2">
                            <p v-if="projekt.status == 'done'">Završen</p>
                            <p v-else-if="projekt.status == 'in_progress'">U tijeku</p>
                            <p v-else-if="projekt.status == 'waiting'">Čeka</p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col cols="4">
                            <p>
                              <b>Klasifikacija</b>
                            </p>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="6" class="pl-2">
                            <p v-if="projekt.classification == 'scientific'">Znanstveni projekt</p>
                            <p v-else-if="projekt.classification == 'expert'">Stručni projekt</p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col cols="4">
                            <p>
                              <b>Područje istraživanja</b>
                            </p>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="6" class="pl-2">
                            <p v-if="projekt.area == 'natural'">Prirodne znanosti</p>
                            <p v-else-if="projekt.area == 'biomedical'">Biomedicina i zdravstvo</p>
                            <p v-else-if="projekt.area == 'technical'">Tehničke znanosti</p>
                            <p v-else-if="projekt.area == 'social'">Društvene znanosti</p>
                            <p v-else-if="projekt.area == 'other'">Ostalo</p>
                            <p
                              v-else-if="projekt.area == 'interdisciplinary'"
                            >Interdisciplinarno područje</p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col cols="4">
                            <p>
                              <b>Opis</b>
                            </p>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="6" class="pl-2">
                            <p>{{projekt.desc}}</p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col cols="4">
                            <p>
                              <b>WEB stranica</b>
                            </p>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="6" class="pl-2">
                            <p>{{projekt.web}}</p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col cols="4">
                            <p>
                              <b>Ustanova</b>
                            </p>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="6">
                            <p>{{projekt.fakultet.name}}</p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
        <v-col id="nastava" cols="12" md="8" v-else-if="informacije == 'nastava'">
          <p class="headline sum-color-bold">Nastava</p>
          <div>
            <v-expansion-panels focusable>
              <v-expansion-panel v-for="(predmet, i) in profesor.predmeti" :key="i">
                <v-expansion-panel-header>
                  <v-row>
                    <v-col cols="12" class="text-left">{{i}}</v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p class="title primary--text">Studijske grupe</p>
                  <div v-for="(item, j) in predmet" :key="j">
                    <span>{{item.studij}}</span>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>-->

    </v-container>

    <v-snackbar v-model="snackbar" :color="color" right :timeout="4000" top>
      {{ snackbar_text }}
      <v-btn dark text @click="snackbar = false">Zatvori</v-btn>
    </v-snackbar>
  </div>
</template>
<script type="text/javascript" src="http://ajax.googleapis.com/ajax/libs/jquery/1.6.2/jquery.min.js"></script>
<script src="Scripts/jquery.googoose.js"></script>
<script>
import axios from "axios";
import {request_url, request_url_hrm} from "../variables.js";
import {VueEditor} from "vue2-editor";
import {asBlob} from "html-docx-js-typescript";
import {saveAs} from "file-saver";

export default {
  components: {
    VueEditor
  },

  mounted() {
    this.getProfileImage()
    this.loggedIn = false;
    this.alias = this.$route.params.alias;
    this.getProfesors()
    this.getUserHRM()

    if (localStorage.getItem("token") !== null) {
      if (localStorage.getItem("aai_kor_ime") == this.alias) {
        this.id = localStorage.getItem("aai_kor_ime");
        this.loggedIn = true;
      }
    }
  },
  methods: {
    update() {
      this.disabled = !this.disabled;
      if (this.disabled) {
        this.snackbar = true;
        this.snackbar_text = "Omogućeno uređivanje";
        this.color = "success";
        this.tooltip_text = "Onemogući uređivanje";
      } else {
        this.snackbar = true;
        this.snackbar_text = "Zabranjeno uređivanje";
        this.color = "error";
        this.tooltip_text = "Omogući uređivanje";
      }
    },

    changeHoverAvatar(value) {
      if (value === 1) {
        this.hover_alert_hrm = true
        setTimeout(() => {
          this.hover_alert_hrm = false
        }, 5000);
      } else if (value === 2) {
        this.hover_alert_cv = true
        setTimeout(() => {
          this.hover_alert_cv = false
        }, 5000);
      } else if (value === 3) {
        this.hover_alert_pub = true
        setTimeout(() => {
          this.hover_alert_pub = false
        }, 5000);
      } else if (value === 4) {
        this.hover_alert_projekti = true
        setTimeout(() => {
          this.hover_alert_projekti = false
        }, 5000);
      }
    },

    getProfileImage() {
      axios
          .get(`https://api.eduid.sum.ba/users/${this.$route.params.alias}/avatar`)
          .then(response => {
            if (response.status == 200)
              this.profile_img_url = `https://api.eduid.sum.ba/users/${this.$route.params.alias}/avatar`
          })
          .catch(
              this.profile_img_url = null
          )
    },
    saveChange() {
      this.loading = true;

      let params = {
        office_number: this.office_number,
        office_place: this.office_place,
        email_private: this.mail,
        // mobile: this.telefon,
        orcid: this.orcid,
        google_scholar_link: this.scholar,
        research_gate_link: this.research_gate,
        biography: this.description,
      }

      axios
          .put(
              request_url_hrm + "users/" + this.alias + "/update_cv",
              params
          )
          .then(response => {
            this.item = response.data;
            this.snackbar = true;
            this.snackbar_text = "Promjene spremljene!";
            this.color = "success";
            this.disabled = !this.disabled;
          })
          .catch(error => {
            console.log(error.message);
            this.snackbar = true;
            this.snackbar_text = "Nešto je pošlo krivo!";
            this.color = "error";
          })
          .finally(() => {
            this.loading = false;
            this.dialog = false;
            this.getUserHRM();
          });
    },
    getUserHRM() {
      axios
          .get(request_url_hrm + "data_for_cv/" + this.alias)
          .then(response => {
            this.hrm_user = response.data

            this.mail = this.hrm_user.email_private
            this.telefon = this.hrm_user.mobile
            this.office_number = this.hrm_user.office_number
            this.office_place = this.hrm_user.office_place
            this.scholar = this.hrm_user.google_scholar_link
            this.research_gate = this.hrm_user.research_gate_link
            this.description = this.hrm_user.biography
            this.orcid = this.hrm_user.orcid
            this.firstLoad = false;
            this.datum_izbora_hrm = this.hrm_user.datum_zadnjeg_izbora
            this.datum_izbora_hrm = this.$moment(this.datum_izbora_hrm).format(
                "DD.MM.YYYY"
            );
          })
          .catch(error => {
            console.log(error.message);
            this.hrm_user = null
          })
    },
    getProfesors() {
      axios
          .get(request_url + "nastavnici/" + this.alias + "?aai=1")
          .then(response => {
            this.profesor = response.data;

            //provjera vidljivosti predmeta
            if (this.loggedIn && localStorage.getItem("aai_kor_ime") == this.alias) {
              Object.keys(this.profesor.predmeti).forEach(key => {
                let brojac = 0
                let val = [];
                val = this.profesor.predmeti[key];
                val.forEach(element => {
                  if (element.vidljivost == true) {
                    brojac += 1
                  }
                });
                if (brojac == 0)
                  delete this.profesor.predmeti[key]
              });
            }

            this.radovi = this.profesor.znanstveni_radovi;

            // var test = response.data.zvanja;
            // this.zvanja.forEach(zvanje => {
            //   if (zvanje.id == this.profesor.zvanje) {
            //     this.zvanje = zvanje.naziv;
            //   }
            // });
            // for (let i = 0; i < test.length; i++) {
            //   if (test.length == i + 1) {
            //     this.datum_izbora = test[i].datum_primanja;
            //   }
            // }
            // this.datum_izbora = this.$moment(this.datum_izbora).format(
            //     "DD.MM.YYYY"
            // );

            // this.mail = this.profesor.e_mail;

            var profesor_projekti = [];
            var profesor_predmeti = [];
            var profesor_radovi = [];
            var profesor_radovi_5g = [];
            this.profesor.projekti.forEach((element) => {
              profesor_projekti.push("<li>" + element.title + "</li>");
            });

            this.profesor.znanstveni_radovi.forEach(element => {
              var date_year = new Date().getFullYear() - 5;
              if (element.godina > date_year) {
                profesor_radovi_5g.push("<li>" + element.naslov + "</li>");
              }
              profesor_radovi.push("<li>" + element.naslov + "</li>");
            });


            //ovo odkomentirati za predmete
            // Object.keys(this.profesor.predmeti).forEach(key => {
            //   profesor_predmeti.push("<li>" + key + "</li>");
            // });

            profesor_projekti.forEach(element => {
              this.profesor_projekti += element;
            });
            profesor_radovi.forEach(element => {
              this.profesor_radovi += element;
            });
            profesor_radovi_5g.forEach(element => {
              this.profesor_radovi_5g += element;
            });
            profesor_predmeti.forEach(element => {
              this.profesor_predmeti += element;
            });

            //   console.log(this.profesor)
            //   this.profesors.forEach(element => {
            //     if (element.id == this.alias) {
            //       //   this.profesor.push(element)
            //       this.profesor = element;
            //     }
            //   });
            this.firstLoad = false;
          })
          .catch(error => {
            console.log(error);
          });
    },

    changeVisibility(value) {
      this.loading = true;
      let vidljivost = []
      // console.log(value)
      value.forEach(element => {
        vidljivost.push({pgi_id: element.pgi_id, web_visibility: element.vidljivost})
      });
      // console.log(vidljivost)
      let test = []
      test.push({vidljivost})
      axios
          .post(request_url + 'nastavnici/' + localStorage.getItem("osobni_id") + "/nastava", test)
          .then(response => {
            if (response.status == 200) {
              // this.item = response.data;
              this.snackbar = true;
              this.snackbar_text = "Promjene spremljene!";
              this.color = "success";
            }
          })
          .catch(error => {
            console.log(error.message);
            this.snackbar = true;
            this.snackbar_text = "Nešto je pošlo krivo!";
            this.color = "error";
          })
          .finally(() => {
            this.loading = false;
            // this.getProfesors();
          });
    },

    showItem(item) {
      console.log(item);
      this.vidljivost = [];
      this.vidljivost = item;
      this.menu = true;
    },

    getView(value) {
      this.informacije = value;
    },

    exportHTML() {
      var vm = this,
          // xmlns:o='urn:schemas-microsoft-com:office:office xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/html40'
          word = `<!DOCTYPE html><html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head>
        <meta http-equiv="Content-Type"0 charset="UTF-8">
        <style>
          table, th, td {
            border: 2px solid black;
            border-collapse: collapse;
          }
          th, td {
            padding: 15px;
          }
          </style><title>Export HTML to Word Document with JavaScript</title></head><body>
          <div class='googoose-wrapper'>
            <div class='googoose body'>
                <table style="width: 100%; border: 1px solid black">
          <tr>
            <td><b>Ime i prezime: </b></td>
            <td>
            ${vm.hrm_user.full_name}</td>
          </tr>
          <tr>
            <td><b>Ustanova zaposlenja: </b></td>
            <td>
            ${vm.hrm_user.organisation?.maticna_ustanova}</td>
          </tr>
          <tr>
            <td><b>E-pošta: </b></td>
            <td>
            ${vm.hrm_user.email}</td>
          </tr>
          <tr>
            <td><b>Internetska stranica: </b></td>
            <td>
              <a href="https://cv.sum.ba/profesori/${
              vm.alias
          }">https://cv.sum.ba/${vm.alias}</a>
            </td>
          </tr>
          <tr>
            <td><b>Akademski stupanj: </b></td>
            <td>
            ${vm.hrm_user.akademski_stupanj}</td>
          </tr>
          <tr>
            <td><b>Zvanje nositelja predmeta: </b></td>
            <td>
            ${vm.hrm_user.zvanje}</td>
          </tr>
          <tr>
            <td><b>Datum zadnjeg izbora u zvanje: </b></td>
            <td>
            ${vm.datum_izbora_hrm}</td>
          </tr>
          <tr>
            <td><b>Kratki životopis: </b></td>
            <td>
            ${vm.hrm_user.biography}</td>
          </tr>
          <tr>
            <td><b>Predmet(i) koje izvodi: </b></td>
            <td>
            <ul>${vm.profesor_predmeti}</ul>
            </td>
          </tr>
          <tr>
            <td><b>Radovi i ostalo što nastavnika kvalificira za izvođenje nastave: </b></td>
            <td>
            <ul>${vm.profesor_radovi}</ul>
            </td>
          </tr>
          <tr>
            <td><b>Projekti: </b></td>
            <td>
            <ul>${vm.profesor_projekti}</ul>
            </td>
          </tr>
          <tr>
            <td><b>Znanstvena bibliografija: </b></td>
            <td>
            <ul></ul>
            </td>
          </tr>
          <tr>
            <td><b>Popis radova objavljenih u posljednjih pet godina: </b></td>
            <td>
            <ul>${vm.profesor_radovi_5g}</ul>
            </td>
          </tr>
          </table>
            </div>
          </div>
          </body></html>`;

      // staro spremanje u .doc

      // var source =
      //   "data:application/vnd.ms-word;charset=utf-8," +
      //   encodeURIComponent(word);
      // var fileDownload = document.createElement("a");
      // document.body.appendChild(fileDownload);
      // fileDownload.href = source;
      // fileDownload.download =
      //   this.profesor.ime + "." + this.profesor.prezime + ".doc";
      // fileDownload.click();
      // document.body.removeChild(fileDownload);

      // novo spremanje u .docx

      asBlob(word).then(data => {
        saveAs(data, this.profesor.ime + "." + this.profesor.prezime + ".docx"); // save as docx file
      });
    },

    previewImage: function (event) {
      if (event) {
        var reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(event);
      }
    },

    logout() {
      const config = {
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}
      };

      axios
          .get(request_url + "auth/logout", config)
          .then(response => {
            // console.log(response.data.success);
            if (response.data.success) {
              localStorage.setItem("token", "-1");
              localStorage.setItem("osobni_id", null);
              localStorage.setItem("aai_kor_ime", null);
              window.location = `/`;
            }
          })
          .catch(error => {
            console.log(error.message);
            localStorage.setItem("token", "-1");
            localStorage.setItem("osobni_id", null);
            localStorage.setItem("aai_kor_ime", null);
            window.location = `/login`;
          });
    }
  },
  data: () => ({
    hrm_user: null,
    word: "",
    snackbar_text: "",
    color: "",
    tooltip_text: "Omogući uređivanje",
    snackbar: false,
    zvanje: "",
    disabled: false,
    vidljivost: [],
    profesors: [],
    alias: "",
    radovi: {},
    profesor: {},
    informacije: "osnovne",
    profile_img_url: null,
    loading: false,
    loggedIn: false,
    scholar: "",
    research_gate: "",
    description: "",
    mail: "",
    office_number: "",
    office_place: '',
    orcid: '',
    image: "",
    item: [],
    telefon: "",
    fileInput: null,
    id: null,
    profesor_projekti: "",
    profesor_radovi: "",
    profesor_radovi_5g: "",
    profesor_bibliografija: "",
    profesor_predmeti: "",
    imageData: "",
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 2.5,
      maxFiles: 1,
      headers: {"My-Awesome-Header": "header value"}
    },
    rules: [
      value =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!"
    ],
    firstLoad: true,
    boilerplate: false,
    tile: false,
    type: "list-item-avatar-three-line",
    types: [],
    tabs: null,
    tab: null,
    dialog: false,
    menu: false,
    location: "",
    telephone_number: "",
    datum_izbora: "",
    datum_izbora_hrm: "",
    hover_alert_hrm: false,
    hover_alert_cv: false,
    hover_alert_pub: false,
    hover_alert_projekti: false,
    list_tabs: [
      {
        id: 1,
        text: "Kratki životopis",
        icon: "mdi-account-box-outline"
      },
      {
        id: 2,
        text: "Objavljeni radovi",
        icon: "mdi-file-document-multiple-outline"
      },
      {
        id: 3,
        text: "Projekti",
        icon: "mdi-book-account-outline"
      },
      // {
      //   text: "Nastava",
      //   icon: "far fa-clock"
      // }
    ],
    zvanja: [
      {
        naziv: "Svi",
        id: ""
      },
      {
        naziv: "Redovni profesor",
        id: "Red. prof."
      },
      {
        naziv: "Docent",
        id: "Doc."
      },
      {
        naziv: "Izvanredni profesor",
        id: "Izv. prof."
      },
      {
        naziv: "Magistar znanosti",
        id: "mr. sc."
      },
      {
        naziv: "Viši asistent",
        id: "V.asis."
      },
      {
        naziv: "Asistent",
        id: "Asis"
      },
      {
        naziv: "Edukator",
        id: "edu"
      }
    ]
  })
};
</script>

<style lang="scss">
.border-profile1 {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(255,0,0,0.12)' stroke-width='3' stroke-dasharray='9%2c 7%2c 9%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}
</style>