import Home from './components/Home'
import Dashboard from './components/Dashboard'
import Profile from './components/Profile'
import Info from "./components/Info";

export const routes = [
    { path: '', component: Home },
    { path: '/korisnici', component: Dashboard },
    { path: '/info', component: Info },
    { path: '/:alias', component: Profile },
]