<template>
  <div>
    <v-container >
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            @keyup="search1"
            single-line
            outlined
            color="#084776"
            hide-details
            label="Unesite ime korisnika..."
            append-icon="fas fa-search"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-progress-circular v-if="loading" :size="50" color="#084776" indeterminate></v-progress-circular>
        </v-col>
        <v-col cols="12">
          <p class="sum-color pl-3">
            Prikazano
            <strong>{{profesors.to}}</strong> od ukupno
            <strong>{{profesors.total}}</strong> rezultata
          </p>
        </v-col>
      </v-row>
      <div style="height: 1px; background: linear-gradient(90deg,#fff,#084776,#fff);"></div>
      <v-row class="pt-4">
        <v-col cols="12" md="4" v-for="(profesor, i) in profesors.data" :key="i">
          <v-card class="mx-auto" max-width="344" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">Zvanje: {{profesor.zvanje}}</div>
                <v-list-item-title
                  class="title mb-1"
                >{{profesor.akad_stupanj ? profesor.akad_stupanj: ''}}{{' ' + profesor.ime + ' ' + profesor.prezime}}</v-list-item-title>
                <v-list-item-subtitle>
                  <b>Matična ustanova:</b>
                </v-list-item-subtitle>
                <v-list-item-subtitle>{{profesor.maticna_ustanova}}</v-list-item-subtitle>
              </v-list-item-content>

              <!-- <v-list-item-avatar tile size="80" color="grey"></v-list-item-avatar> -->
            </v-list-item>

            <v-card-actions>
              <router-link :to="'/' + profesor.aai_kor_ime" class="link">
                <v-btn text left color="#084776">
                  <v-icon left>far fa-address-card</v-icon>Prikaži profil
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn fab small color="#084776" left class="mr-2" @click="prev()" :disabled="diss1">
            <v-icon color="white">fas fa-chevron-left</v-icon>
          </v-btn>
          <v-btn fab small color="#084776" left class="ml-2" @click="next()" :disabled="diss">
            <v-icon color="white">fas fa-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
      // this.search = this.search.replace(/\s+/g, '%20')
      // console.log(this.search)
    }
    this.getProfesors();
  },

  methods: {
    getProfesors() {
      this.loading = true;
      var url = "";
      // console.log(this.page)
      // if(this.search){
      url =
        "https://api.sum.ba/nastavnici?page=" +
        this.page +
        "&search=" +
        this.search.replace(/\s+/g, "%20");
      // }
      // else{
      //   url =
      //   "https://api.sum.ba/nastavnici?page=" + this.page +  "&sastavnica=5&search="
      // }

      axios
        .get(url)
        .then(response => {
          this.profesors = response.data;
          // console.log(response.data)
          if (this.profesors.total < 15) {
            this.diss = true;
          } else {
            this.diss = false;
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(error => {
          console.log(error.message);
        });
    },

    search1: function() {
      // clear timeout variable
      clearTimeout(this.timeout);
      // this.loading = true;
      var self = this;
      this.loading = true;
      this.timeout = setTimeout(function() {
        self.outputValue = self.search;
        // self.prev = 1;
        // self.next = 10;
        self.getProfesors().then(value => {
          self.profesors = value;
        });
      }, 1000);
    },

    next() {
      if (this.profesors.last_page > this.page) {
        this.diss = false;
        this.diss1 = false;
        this.page++;
        this.getProfesors();
        if (this.profesors.last_page == this.page) {
          this.diss = true;
        }
      }
    },

    prev() {
      if (this.page > 1) {
        this.diss = false;
        this.page--;
        this.getProfesors();
      }
      if (this.page == 1) {
        this.diss1 = true;
      }
    }
  },

  data: () => ({
    search: "",
    profesors: [],
    loading: false,
    page: 1,
    diss: false,
    diss1: true
  })
};
</script>
