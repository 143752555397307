<template>
  <div>
    <v-container fluid>
      <v-row align="center" justify="center">
        
        <v-col cols="12" md="5" class="pl-10">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <p class="display-2 hidden-xs-only mb-0" style="color: #084776">CV aplikacija</p>
              <p class="title hidden-sm-and-up mb-0" style="color: #084776">CV aplikacija</p>
            </v-col>
            <v-col cols="12">
              <p>Dobrodošli na stranice Sveučilišta u Mostaru. Na ovom dijelu sustava možete pronaći detaljne informacije o nastavnom i nenastavnom osoblju svih naših ustrojbenih jedinica.</p>
            </v-col>
            <v-col cols="12">
          <v-text-field
            clearable
            outlined
            rounded
            label="Pretražite korisnike..."
            color="#084776"
            append-icon="fas fa-search"
            @click:append="searchUsers()"
            v-model="search"
            v-on:keyup.enter.native="searchUsers"
          ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="7">
          <v-img :src="require('@/assets/img/vizual1.svg')"></v-img>
        </v-col>
      </v-row>
    </v-container>

<!--    <v-container>-->
<!--      <v-row>-->
<!--        <v-col cols="12">-->
<!--          <v-card class="pa-3">-->
<!--            <span class="grey&#45;&#45;text subtitle">*Nastavnike je moguće filtrirati po studiju!</span>-->
<!--            <v-row align="center" justify="center">-->
<!--              <v-col cols="12" md="12">-->
<!--                <v-autocomplete-->
<!--                  clearable-->
<!--                  outlined-->
<!--                  color="#084776"-->
<!--                  label="Odaberite studij *"-->
<!--                  hide-details-->
<!--                  v-model="selected_stuies"-->
<!--                  :items="studies"-->
<!--                  item-text="naziv"-->
<!--                  item-value="id"-->
<!--                  prepend-inner-icon="far fa-bookmark"-->
<!--                ></v-autocomplete>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--            <div style="height: 1px; background: linear-gradient(90deg,#fff,#084776,#fff);"></div>-->
<!--            <v-row>-->
<!--              <v-col cols="12" class="text-center" v-if="loading_dialog">-->
<!--                <v-progress-circular :size="60" :width="5" color="#B70000" indeterminate></v-progress-circular>-->
<!--              </v-col>-->
<!--              <v-col cols="12" v-else>-->
<!--                <div v-if="form.uposlenici">-->
<!--                  <h2 class="title" style="color: #B70000">Popis nastavnika</h2>-->
<!--                  <v-row>-->
<!--                    <v-col cols="12">-->
<!--                      <v-card-->
<!--                        hover-->
<!--                        class="mt-3"-->
<!--                        v-for="(item, i) in form.uposlenici"-->
<!--                        :key="i"-->
<!--                        :to="'profesori/' + item.aai_kor_ime"-->
<!--                      >-->
<!--                        <v-row align="center">-->
<!--                          <v-col cols="10">-->
<!--                            <v-row>-->
<!--                              <v-col cols="12" md="5" xs="12">-->
<!--                                <h2-->
<!--                                  class="title px-4 pt-3 hidden-xs-only"-->
<!--                                  style="color: #084776"-->
<!--                                >{{item.akad_stupanj.kratica + " " + item.ime + " " + item.prezime}}</h2>-->
<!--                                <h5-->
<!--                                  class="hidden-sm-and-up px-4 pt-3"-->
<!--                                  style="color: #084776"-->
<!--                                >{{item.akad_stupanj.kratica + " " + item.ime + " " + item.prezime}}</h5>-->
<!--                                <v-card-subtitle class="pt-1">ZVANJE: {{item.zvanje.kratica}}</v-card-subtitle>-->
<!--                              </v-col>-->
<!--                              <v-col cols="12" md="7">-->
<!--                                <v-card-subtitle class="pb-1">-->
<!--                                  <b>Matična ustanova:</b>-->
<!--                                </v-card-subtitle>-->
<!--                                <v-card-subtitle class="pt-0">{{item.org_struktura.naziv}}</v-card-subtitle>-->
<!--                              </v-col>-->
<!--                            </v-row>-->
<!--                          </v-col>-->
<!--                          <v-col cols="2" class="text-center pl-0">-->
<!--                            <v-icon color="#084776">far fa-address-card</v-icon>-->
<!--                          </v-col>-->
<!--                        </v-row>-->
<!--                      </v-card>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                </div>-->
<!--                <div v-else>-->
<!--                  <v-alert outlined type="warning" prominent border="left">Morate odabrati studij!</v-alert>-->
<!--                </div>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--      </v-row>-->

<!--      <v-dialog v-model="dialog" width="500">-->
<!--        <v-card>-->
<!--          <div style="background-color: #B70000">-->
<!--            <h2 class="title pa-5" style="color: white">Popis nastavnika za studij: {{form.naziv}}</h2>-->
<!--          </div>-->

<!--          <div style="height: 1px; background: linear-gradient(90deg,#fff,#084776,#fff);"></div>-->

<!--          <v-card-actions>-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-btn outlined color="#B70000" text @click="dialog = false">Zatvori</v-btn>-->
<!--          </v-card-actions>-->
<!--        </v-card>-->
<!--      </v-dialog>-->
<!--    </v-container>-->
  </div>
</template>

<script>
import axios from "axios";
import { request_url } from "../variables.js";
export default {
  mounted() {
    this.loggedIn = false;
    this.alias = this.$route.params.alias;

    if (localStorage.getItem("token") !== "-1") {
      if (localStorage.getItem("aai_kor_ime") == this.alias) {
        // console.log(localStorage.getItem("aai_kor_ime"), this.alias)
        this.id = localStorage.getItem("aai_kor_ime");
        this.loggedIn = true;
      }
    }

    this.getStudies();
  },
  watch: {
    selected_stuies: function() {
      // console.log(this.selected_stuies);
      // this.selected_stuies = []
      this.showItem(this.selected_stuies);
    }
  },
  computed: {
    filteredList() {
      return this.studies.filter(post => {
        return post.naziv
          .toLowerCase()
          .includes(this.search_studies.toLowerCase());
      });
    }
  },
  methods: {
    searchUsers() {
      this.$router.push({
        path: "/korisnici",
        query: { search: this.search }
      });
      //   this.scrollToTop();
    },
    getStudies() {
      this.loading = true;
      axios
        .get(request_url + "nastavnici/studiji?sastavnica=5")
        .then(response => {
          this.studies = response.data;
          // console.log(this.studies);
          // this.studies.data.forEach(element => {
          //   console.log(element.studij.naziv)
          // });
          // console.log(this.studies)
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showItem(item) {
      this.loading_dialog = true;
      this.form = [];
      axios
        .get(request_url + "nastavnici/studiji/" + item)
        .then(response => {
          this.form = response.data;
          // console.log(this.form)
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading_dialog = false;
        });
      // this.form = Object.assign({}, item);
      // this.dialog = true;
    },
    logout() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      };

      axios
        .get(request_url + "auth/logout", config)
        .then(response => {
          console.log(response.data.success);
          if (response.data.success) {
            localStorage.setItem("token", "-1");
            localStorage.setItem("aai_kor_ime", null);
            localStorage.setItem("osobni_id", null);
            window.location = `/`;
          }
        })
        .catch(error => {
          console.log(error.message);
          localStorage.setItem("token", "-1");
          localStorage.setItem("aai_kor_ime", null);
          localStorage.setItem("osobni_id", null);
          window.location = `/`;
        });
    }
  },
  data: () => ({
    search: "",
    id: null,
    loggedIn: false,
    dialog: false,
    loading: false,
    loading_dialog: false,
    form: [],
    search_studies: "",
    alias: "",
    selected_stuies: "",
    items: [{ title: "Naslovnica", link: "/", icon: "fas fa-home" }],
    studies: [],
    studiji: [
      {
        id: 1,
        title: "Jednopredmetni studij informatike",
        predmeti: [
          {
            title: "Informatika",
            razredi: [
              {
                title: "Prosječna ocjena",
                kratica: "ocjena_inf"
              }
            ]
          },
          {
            title: "Matematika",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_mat_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_mat_4"
              }
            ]
          }
        ]
      },
      // {
      //   id: 2,
      //   title: "Dvopredmetni studij Matematike",
      //   predmeti: [
      //     {
      //       title: "Matematika",
      //       razredi: [
      //         {
      //           title: "Ocjena III. razred",
      //           kratica: "ocjena_mat_3"
      //         },
      //         {
      //           title: "Ocjena IV. razred",
      //           kratica: "ocjena_mat_4"
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   id: 3,
      //   title: "Dvopredmetni studij Biologije",
      //   predmeti: [
      //     {
      //       title: "Biologija",
      //       razredi: [
      //         {
      //           title: "Ocjena III. razred",
      //           kratica: "ocjena_bio_3"
      //         },
      //         {
      //           title: "Ocjena IV. razred",
      //           kratica: "ocjena_bio_4"
      //         }
      //       ]
      //     }
      //   ]
      // },
      {
        id: 4,
        title: "Dvopredmetni studij Matematika i Informatika",
        predmeti: [
          {
            title: "Informatika",
            razredi: [
              {
                title: "Prosječna ocjena",
                kratica: "ocjena_inf"
              }
            ]
          },
          {
            title: "Matematika",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_mat_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_mat_4"
              }
            ]
          }
        ]
      },
      {
        id: 5,
        title: "Dvopredmetni studij Kemija i Biologija",
        predmeti: [
          {
            title: "Kemija",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_kem_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_kem_4"
              }
            ]
          },
          {
            title: "Biologija",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_bio_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_bio_4"
              }
            ]
          }
        ]
      },
      {
        id: 6,
        title: "Dvopredmetni studij Matematika i Fizika",
        predmeti: [
          {
            title: "Matematika",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_mat_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_mat_4"
              }
            ]
          },
          {
            title: "Fizika",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_fiz_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_fiz_4"
              }
            ]
          }
        ]
      },
      {
        id: 7,
        title: "Jednopredmetni studij Biologije",
        predmeti: [
          {
            title: "Biologija",
            razredi: [
              {
                title: "Ocjena I. razred",
                kratica: "ocjena_bio_1"
              },
              {
                title: "Ocjena II. razred",
                kratica: "ocjena_bio_2"
              },
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_bio_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_bio_4"
              }
            ]
          }
        ]
      },
      {
        id: 8,
        title: "Jednopredmetni studij Fizike",
        predmeti: [
          {
            title: "Fizika",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_fiz_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_fiz_4"
              }
            ]
          }
        ]
      },
      {
        id: 9,
        title: "Jednopredmetni studij Gografije",
        predmeti: [
          {
            title: "Zemljopis",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_zem_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_zem_4"
              }
            ]
          }
        ]
      },
      {
        id: 10,
        title: "Jednopredmetni studij Kemije",
        predmeti: [
          {
            title: "Kemija",
            razredi: [
              {
                title: "Ocjena I. razred",
                kratica: "ocjena_kem_1"
              },
              {
                title: "Ocjena II. razred",
                kratica: "ocjena_kem_2"
              },
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_kem_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_kem_4"
              }
            ]
          },
          {
            title: "Matematika",
            razredi: [
              {
                title: "Ocjena I. razred",
                kratica: "ocjena_mat_1"
              },
              {
                title: "Ocjena II. razred",
                kratica: "ocjena_mat_2"
              },
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_mat_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_mat_4"
              }
            ]
          }
        ]
      },
      {
        id: 11,
        title: "Jednopredmetni studij Matematike",
        predmeti: [
          {
            title: "Matematika",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_mat_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_mat_4"
              }
            ]
          }
        ]
      },
      {
        id: 12,
        title: "Jednopredmetni studij Pedagogije",
        predmeti: []
      },
      {
        id: 13,
        title: "Studij Promet i logistika",
        predmeti: []
      },
      {
        id: 14,
        title: "Smjer Turizam i zaštita okoliša",
        predmeti: [
          {
            title: "Zemljopis",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_zem_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_zem_4"
              }
            ]
          }
        ]
      },
      {
        id: 15,
        title: "Stručni studij Kineziologije",
        predmeti: []
      },
      {
        id: 16,
        title: "Stručni studij Predškolski odgoj",
        predmeti: [
          {
            title: "Hrvatski jezik",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_hrv_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_hrv_4"
              }
            ]
          },
          {
            title: "Likovna kultura",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_lik_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_lik_4"
              }
            ]
          },
          {
            title: "Glazbena kultura",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_gla_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_gla_4"
              }
            ]
          }
        ]
      },
      {
        id: 17,
        title: "Studij Glazbene umjetsnosti",
        predmeti: []
      },
      {
        id: 18,
        title: "Studij Edukacijske rehabilitacije",
        predmeti: []
      },
      {
        id: 19,
        title: "Studij Razredne nastave",
        predmeti: [
          {
            title: "Hrvatski jezik",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_hrv_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_hrv_4"
              }
            ]
          },
          {
            title: "Matematika",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_mat_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_mat_4"
              }
            ]
          },
          {
            title: "Likovna kultura",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_lik_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_lik_4"
              }
            ]
          },
          {
            title: "Glazbena kultura",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_gla_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_gla_4"
              }
            ]
          }
        ]
      },
      {
        id: 20,
        title: "Studij Znanost o okolišu",
        predmeti: [
          {
            title: "Biologija",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_bio_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_bio_4"
              }
            ]
          },
          {
            title: "Kemija",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_kem_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_kem_4"
              }
            ]
          },
          {
            title: "Zemljopis",
            razredi: [
              {
                title: "Ocjena III. razred",
                kratica: "ocjena_zem_3"
              },
              {
                title: "Ocjena IV. razred",
                kratica: "ocjena_zem_4"
              }
            ]
          }
        ]
      },
      {
        id: 21,
        title: "Studij Kineziologije",
        predmeti: []
      }
    ]
  })
};
</script>