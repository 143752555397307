"use strict";

import Vue from "vue";
import axios from "axios";

let config = {
    baseURL: 'https://api.sum.ba/',
    // timeout: 60 * 1000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
    headers: {
        Accept: "application/json",
    }, // Check cross-site Access-Control
};

const baseUrl = 'https://api.sum.ba/';
const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response;
    },
    function(error) {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
            window.location.href = `${baseUrl}sso/login?redirect_to=${location.href}`;
        }

        if (code === 403) {
            console.log('Nemate pristup ovom djelu sustava')
        }

        // Do something with response error
        return Promise.reject(error);
    }
);

Plugin.install = function(Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                console.log(options); // TODO (custom): izbriši ovo..
                return _axios;
            },
        },
        $axios: {
            get() {
                return _axios;
            },
        },
    });
};

Vue.use(Plugin);

axios.defaults.baseURL = baseUrl;
axios.defaults.withCredentials = true;
axios.defaults.headers.accept = "application/json";

export default Plugin;
